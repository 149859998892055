<template>
  <div class="q-pa-xs col">
    <!-- MUESTRA EL RESUMEN DE OFICINAS -->
    <div class="row"
         v-if="route.params.id == 'Semanal'">
      <div class="col-xs-12 col-sm-2 full">
        <q-select outlined
                  dense
                  v-model="numLiq"
                  @update:model-value="init"
                  color="text-primary"
                  stack-label
                  :options="numLiqs"
                  label="Número de Liq.:"></q-select>
      </div>
      <div class="col-xs-12 col-sm-2">
        <q-input outlined
                 v-model="eur.oficina"
                 dense
                 :label="`Oficina ${global.user.area}`"
                 input-class="text-right"
                 stack-label
                 step=".01">
          <template v-slot:prepend>
            <q-icon name="payments"></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-xs-12 col-sm-2">
        <q-input outlined
                 v-model="eur.banco"
                 dense
                 label="Banco (LaCaixa)"
                 input-class="text-right"
                 stack-label
                 step=".01">
          <template v-slot:prepend>
            <q-icon name="account_balance"></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-xs-12 col-sm-2">
        <q-input outlined
                 v-model="eur.anticipo"
                 dense
                 :label="`Anticipos ${global.user.area}`"
                 input-class="text-right"
                 stack-label
                 step=".01">
          <template v-slot:prepend>
            <q-icon name="savings"></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-xs-12 col-sm-4">
        <q-input outlined
                 v-model="eur.reale"
                 bg-color="grey-4"
                 dense
                 label="Reale (Liquidación)"
                 input-class="text-right"
                 stack-label
                 step=".01">
          <template v-slot:prepend>
            <q-icon name="real_estate_agent"></q-icon>
          </template>
          <template v-slot:append>
            <div v-if="eur.RF !== null"
                 class="text-caption">
              {{ eur.RF }}
            </div>
          </template>
        </q-input>
      </div>
    </div>
  </div>
  <!-- MENSAJE DE FALTA DE DINERO EN BANCO -->
  <q-banner v-if="alert"
            inline-actions
            rounded
            class="bg-negative text-yellow">
    REALE cobrará {{ eur.realeFull }} entre las 2 oficinas y en el banco solo hay {{ eur.banco }} disponibles. INGRESA URGENTEMENTE!
  </q-banner>
  <!-- DIALOGO DE LIQUIDACION -->
  <q-dialog v-model="showLiq"
            persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6 text-red">ATENCIÓN:</div>
        <div class="text-h6 text-secondary">SE VA A PROCEDER A LIQUIDAR CUENTAS</div>
      </q-card-section>
      <q-card-section>
        <div>Verifica que la previsión de Dare coincide con la Real</div>
        <div><strong>EN CASO CONTRARIO, NO LIQUIDES Y AVERIGUA ERRORES</strong></div>


      </q-card-section>
      <!-- Componente q-upload -->
      <q-card-section>
        Se permite subir el documento escaneado de esta liquidación
        <q-uploader :url="url"
                    auto-upload
                    label="Subir archivos"
                    field-name="file"
                    :form-fields="[{ name: 'folder', value: folder }, { name: 'name', value: file + extrafilename }]"
                    multiple
                    @uploaded="onUpload"
                    style="max-width: 300px"></q-uploader>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat
               label="Cancelar"
               color="secondary"
               @click="showLiq = false" />
        <q-btn flat
               label="Confirmar"
               color="primary"
               @click="onLiquidar()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <!-- MUESTRA LA TABLA -->
  <DataTable ref="DT"
             :opts="opts"
             @onFilter="onFilter"
             @onUpdate="onUpdate"
             @onSelRow="onSelRow">
    <template #buttons>
      <template v-if="sel === true">
        <q-btn v-if="selRec.Val == 0"
               color="primary"
               dense
               label="VALIDAR"
               @click="unValid(1)"></q-btn>
        <q-btn v-if="selRec.Val == 1"
               color="primary"
               dense
               label="DESVALIDAR"
               @click="unValid(0)"></q-btn>
      </template>
      <template v-if="sel !== true">
        <q-btn v-if="validacionAct"
               dense
               color="primary"
               label="VALIDAR TODO"
               @click="validacion()"></q-btn>
        <q-btn v-if="liquidacionAct"
               dense
               color="positive"
               label="LIQUIDAR TODO"
               @click="showLiq = true"></q-btn>
      </template>
    </template>
  </DataTable>
  <div class="row">
    <q-chip dense
            square
            :style="{ backgroundColor: '#b6ffbf', color: 'black' }"
            icon="euro_symbol"> Validado</q-chip>
    <q-chip dense
            square
            :style="{ backgroundColor: '#88c9ff', color: 'black' }"
            icon="euro_symbol"> Recibo pagado en liquidación
      anterior</q-chip>
  </div>
</template>

<script setup>
import DataTable from "@/components/DataTable.vue"
import { date, Notify, Dialog } from "quasar";
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
const DT = ref()
// APP NEW
const numLiq = ref(false);
const numLiqs = ref([]);
const showLiq = ref(false)
const sel = ref()
const selRec = ref()
const eur = ref({
  reale: null,
  realeFull: null,
  RF: null,
  banco: null,
  oficina: null,
  anticipo: null
})
const EReale = ref(); // el valor de Euros Reale sin formatear para crear la línea de liquidación
const data = ref([])
const alert = ref(false)
const opts = ref({
  table: '',
  url: null,
  post: null,
  upd: "FechaRegistro"
})
const msgDiag = ref('')
const validacionAct = ref()
const liquidacionAct = ref()

// UPLOAD
const url = window.location.protocol + "//" + window.location.hostname + "/docs/upload"
const folder = ref()
const file = ref();
// FUNCTIONS
function onSelRow(d) {
  if (d !== false) {
    sel.value = true
    selRec.value = d
  } else {
    sel.value = false
  }
}
// onFilter se ejecuta tanto al filtrar como despues de actualizar
function onFilter(d) { }
function onUpdate(d) {
  data.value = d.data
  let dd = d.data
  let dc = d.cols
  opts.value.defs = { Usuario: global.user.name, Mediador: global.user.area, Semana: global.data.getWeek(), FechaEfecto: global.data.getDate() }
  validacionAct.value = global.data.getSum(dd, "Val") < dd.length ? true : false // Num Registros > Registros con Val(1)?
  liquidacionAct.value = global.data.getSum(dd, "Val") == dd.length ? true : false // Num Registros = Registros Validados?
  let numliq = global.data.get(dc, "FLD", "NumLiq", "LST")
  numliq.unshift(getNumLiq())
  numLiqs.value = numliq
}
function unValid(v) {
  global.sql("/db/update/Liquidaciones", { data: { Val: v }, where: { id: selRec.value.id } }).then(() => { sel.value = false; update() })
}
function validacion() {
  Dialog.create({
    title: 'Validación Agencia' + global.user.area,
    message: 'Confirma que las liquidaciones coinciden con los datos de DARE, en caso contrario no confirmes hasta ver donde está el error, si persiste el descuadre, debes marcar la caja y el quebranto de caja existente',
    persistent: true,
    cancel: true
  }).onOk(() => {
    global.sql("/db/update/Liquidaciones", {
      where: { Val: 0, Mediador: global.user.area, NumLiq: numLiq.value },
      data: { Val: 1 }
    }).then(global.sql("/db/insert/Liquidaciones", {
      data: {
        FechaEfecto: global.data.getDate(0),
        Mediador: global.user.area,
        Detalle: "OF: " + eur.value.oficina + " - BA: " + eur.value.banco + " - AN: " + eur.value.anticipo + " - RE: " + eur.value.reale + " (" + eur.value.RF + ")",
        Usuario: global.user.name,
        Origen: "VALIDACION",
        Destino: "LIQUIDACION",
        Val: 1,
      }
    })).then(setTimeout(update, 600))
  })
}
function onLiquidar() {
  global.sql("/db/insert/Liquidaciones", {
    data: {
      FechaEfecto: global.data.getDate(0),
      Mediador: global.user.area,
      Detalle: "OF: " + eur.value.oficina + " - BA: " + eur.value.banco + " - AN: " + eur.value.anticipo + " - RE: " + eur.value.reale + " (" + eur.value.RF + ")",
      Usuario: global.user.name,
      Importe: EReale.value,
      Origen: "BANCO",
      Destino: "REALE",
      Val: 1,
    }
  }).then(setTimeout(update, 600))
}
async function update() {
  try {
    await DT.value.update(opts.value.url, opts.value.post);
    await updateLiquidaciones();
  } catch (error) {
    console.error("Error en la actualización:", error);
  }
}
function updateLiquidaciones() {
  global.sql("/db/LiquidacionesR/Liquidaciones", { where: { NumLiq1: numLiq.value, NumLiq2: numLiq.value } }, (r) => {
    if (r.success && Array.isArray(r.data) && r.data.length > 0) {
      eur.value.banco = parseFloat(global.data.getSum(r.data, "B")).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
      eur.value.realeFull = parseFloat(global.data.getSum(r.data, "R")).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
      let Med = global.data.getRow(r.data, "M", global.user.area)
      if (Med !== undefined) {
        eur.value.oficina = parseFloat(Med.O).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
        eur.value.anticipo = parseFloat(Med.A).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
        eur.value.reale = parseFloat(Med.R).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
        eur.value.RF = parseFloat(Med.RF) > 0 ? parseFloat(global.data.getSum(r.data, "RF")).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true }) : null
        // aplico el valor de la base de datos ya que los otros estan formateados con 0,00 € y no hace la comparativa de alerta
        Number(global.data.getSum(r.data, "R")) > Number(global.data.getSum(r.data, "B")) ? alert.value = true : alert.value = false
        EReale.value = parseFloat(Med.R.toFixed(2));
      } else
        Notify.create({ message: "No se ha podido obtener datos de Oficina", color: "negative" })
    }
  })
}
function setNumLiq() {
  numLiq.value === false ? numLiq.value = getNumLiq() : ""
  file.value = numLiq.value + "_" + global.user.name + "_"
}
function getNumLiq() {
  var hoy = new Date();
  var pend = 1 + (7 - hoy.getDate()) % 7;
  var pastL = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 7 + pend); // fecha del pasado lunes
  var ano = date.formatDate(hoy, "YY")
  var week = date.formatDate(hoy, "ww")
  var liq = hoy.getDate() <= 7 && hoy.getDate() < pastL.getDate() ? 1 : 0 // si hoy es inicio de mes y esta en la semana actual es 1, sino es 0
  var end = ano.toString() + week.toString() + liq
  return parseInt(end);
}
function init() {
  opts.value.defs = { Usuario: global.user.name, Mediador: global.user.area } // cuando se crea un nuevo registro, Usuario = nombre de usuario actual
  opts.value.table = "Liquidaciones"
  opts.value.url = "/db/select/Liquidaciones"
  folder.value = global.user.area + '/' + opts.value.table + '/'
  setNumLiq()
  if (route.params.id == "Semanal")
    opts.value.post = { where: { Mediador: global.user.area, NumLiq: numLiq.value }, order: ["FechaRegistro", "DESC"] }
  if (route.params.id == "Todos")
    opts.value.post = { where: { Mediador: global.user.area }, order: ["FechaRegistro", "DESC"] }
  update()

}
onMounted(() => { init() })
watch([route, () => global.user.area], () => { init() });
</script>

<style></style>