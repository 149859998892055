<template>
  <q-layout view="hHh LpR fFf">
    <q-header reveal
              elevated
              class="bg-primary text-white">
      <!-- TOOLBAR -->
      <q-toolbar dense>
        <q-btn dense
               flat
               round
               icon="menu"
               @click="showMenu = !showMenu"></q-btn>
        <q-toolbar-title> DARE v{{ version }}
        </q-toolbar-title>
        <!-- <q-btn flat round dense icon="person" @click="logged === true ? logout() : showUserLogin = true" :color="userIcon"> -->
        <q-select dense
                  outlined
                  bg-color="secondary"
                  v-model="global.user.area"
                  @update:model-value="changeArea()"
                  :options="global.user.areas">
          <template v-slot:prepend>
            <q-icon name="museum" />
          </template>
        </q-select>
        <!-- Ícono de Usuario con Menú -->
        <q-btn flat
               round
               dense
               icon="person"
               :color="userIcon"
               ref="userButton">
          <q-menu auto-close>
            <q-list dense>
              <q-item v-if="global.user.logged"
                      class="bg-primary text-secondary">
                <q-item-section avatar><q-icon name="person" /></q-item-section>
                <q-item-section>{{ global.user.name }}</q-item-section>
              </q-item>
              <q-item v-if="global.user.logged"
                      clickable
                      :to="'/Mi/sso'">
                <q-item-section avatar><q-icon name="account_circle" /></q-item-section>
                <q-item-section>Mi Cuenta</q-item-section>
              </q-item>
              <q-item v-if="global.user.logged"
                      clickable
                      :to="'/Mi/chat'">
                <q-item-section avatar><q-icon name="chat" /></q-item-section>
                <q-item-section>Mi Chat</q-item-section>
              </q-item>
              <q-item v-if="global.user.logged"
                      clickable
                      :to="'/Mi/drive'">
                <q-item-section avatar><q-icon name="cloud" /></q-item-section>
                <q-item-section>Mi Drive</q-item-section>
              </q-item>
              <q-item v-if="global.user.logged"
                      clickable
                      @click="global.log.off">
                <q-item-section avatar><q-icon name="logout" /></q-item-section>
                <q-item-section>Cerrar Sesión</q-item-section>
              </q-item>
              <q-item v-else
                      clickable
                      @click="global.log.on">
                <q-item-section avatar><q-icon name="login" /></q-item-section>
                <q-item-section>Iniciar Sesión</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <!-- <q-btn flat round dense icon="person" @click="global.log.off()" :color="userIcon"> -->
        <!-- </q-btn> -->
      </q-toolbar>
    </q-header>
    <!-- MENU  -->
    <q-drawer v-if="global.user.logged === true"
              show-if-above
              v-model="showMenu"
              side="left"
              elevated>
      <q-list dense
              bordered
              separator>
        <q-item to="/"
                exact>
          <q-item-section avatar>
            <q-icon name="home"></q-icon>
          </q-item-section>
          <q-item-section>Inicio</q-item-section>
        </q-item>
      </q-list>
      <q-list bordered>
        <div v-for="(menu, m1) in menu"
             :key="m1">
          <q-expansion-item dense
                            group="somegroup"
                            header-class="text-primary"
                            :icon="menu.icon"
                            :label="menu.text">
            <template v-slot:header>
              <q-item-section avatar>
                <q-avatar :icon="menu.icon"></q-avatar>
              </q-item-section>
              <q-item-section>
                {{ menu.text }}
              </q-item-section>
            </template>
            <div v-for="(subMenu, m2) in menu.subMenu"
                 :key="m2">
              <q-item dense
                      :to="subMenu.to"
                      exact>
                <q-item-section avatar></q-item-section>
                <q-item-section>
                  {{ subMenu.text }}
                </q-item-section>
                <q-item-section avatar>
                  <q-icon name="arrow_right"></q-icon>
                </q-item-section>
              </q-item>
            </div>
          </q-expansion-item>
          <q-separator></q-separator>
        </div>
      </q-list>
    </q-drawer>
    <!-- DIALOGO DE LOGIN USUARIO -->
    <q-dialog v-model="showUserLogin"
              position="top">
      <q-card>
        <q-form @submit="login">
          <q-card-section>
            <q-input filled
                     v-model="user"
                     label="Usuario"
                     type="text"
                     lazy-rules
                     autocomplete="username"
                     :rules="[(val) => (val && val.length > 0) || 'Escriba su usuario']"></q-input>
            <q-input filled
                     type="password"
                     v-model="pass"
                     label="Contraseña"
                     lazy-rules
                     autocomplete="current-password"
                     clearable
                     clear-icon="close"
                     :rules="[(val) => (val !== null && val !== '') || 'Escriba su contraseña']"></q-input>
          </q-card-section>
          <q-card-actions align="around">
            <q-btn flat
                   color="negative"
                   @click="showUserLogin = false">CANCELAR</q-btn>
            <q-btn flat
                   color="positive"
                   type="submit">ACEPTAR</q-btn>
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
    <!-- PAGINA CENTRAL -->
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { onMounted, computed, provide, ref, watch } from "vue";
import { Notify } from "quasar";
import { useRouter, useRoute } from "vue-router"
import global from "./global"
// import menujson from "./menu.json"
import { version } from "../package.json"
provide("global", global)
const router = useRouter();
// ***************---------------------EXCLUSIVOS PARA USO EN APP.VUE-----------***************
const showUserLogin = ref(false);
const user = ref()
const pass = ref()
const showMenu = ref(false);
const userIcon = computed(() => (global.user.logged ? "green" : "red"));
const menu = ref();

function changeArea() {
  global.log.check()
  Notify.create({ message: "Entorno Mediador " + global.user.area, color: "info" });
}
function init() {
  let connected = global.log.check()
  menu.value = global.user.menu;
  if (connected) {
    var url = window.location.href;
    var params = new URLSearchParams(url.split('#/')[1]);
    var token = params.get('access_token') ?? false;
    if (token)
      setTimeout(() => router.push({ path: "/", force: true }), 3000);
  }
}
onMounted(() => { init() })
// Añadir un watch para global.user.menu
watch(() => global.user.menu, (newMenu) => {
  menu.value = newMenu;
}, { deep: true });

// Añadir un watch para global.user.logged
watch(() => global.user.logged, (newLoggedStatus) => {
  if (newLoggedStatus) {
    init();
  } else {
    menu.value = [];
  }
});
</script>