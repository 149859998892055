<template>
  <div class="q-ma-xs q-mt-xs">
    <div>
      <DataTable ref="DT"
                 :opts="opts"
                 @onSelRow="onSelRow"
                 @onFilter="onFilter"
                 @onUpdate="onUpdate">
        <template #buttons>
          <q-btn-toggle v-model="subTable"
                        @update:model-value="updateSubTable()"
                        toggle-color="primary"
                        :options="[
                          { label: 'Recibos', value: 'Recibos' },
                          { label: 'Siniestros', value: 'Siniestros' }
                          // { label: 'Siniestros', value: 'Clientes_Siniestros' }
                        ]" />
          <!-- <template v-if="sel === true">
            <q-btn color="primary"
                   label="Pólizas"
                   @click="DT.tab = tab = 'tabA'"></q-btn>
            <q-btn color="primary"
                   label="Siniestros"
                   @click="DT.tab = tab = 'tabB'"></q-btn>
          </template> -->
        </template>
        <template #tabA>
          <!-- <q-bar class="bg-secondary text-black rounded-borders">
            <q-space></q-space>
            <q-btn color="primary"
                   label="Volver"
                   @click="volver"></q-btn>
          </q-bar>
          <q-card class="my-card">
            <q-card-section>
              <q-list bordered
                      separator>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>CLIENTE </q-item-label>
                    <q-item-label>{{ cliente.Cliente }} [ {{ cliente.NIF }} ]</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>DIRECCION </q-item-label>
                    <q-item-label> {{ cliente.Direccion }} {{ cliente.Poblacion }} {{ cliente.CP }} {{ cliente.Provincia }} </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>CONTACTOS </q-item-label>
                    <q-item-label> <q-chip outline
                              square
                              icon="call"> {{ cliente.Movil }}</q-chip>
                      <q-chip outline
                              square
                              icon="tty"> {{ cliente.Fijo }}</q-chip>
                      <q-chip outline
                              square
                              icon="mail"> {{ cliente.Correo }}</q-chip>
                      <q-chip outline
                              square
                              icon="account_balance"> {{ cliente.Banco }}</q-chip> </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>NOTAS </q-item-label>
                    {{ cliente.Notas }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>
          <div class="q-pa-md">
            <q-table dense
                     title="Pólizas"
                     :rows="rows"
                     :columns="columnsPol"
                     row-key="name">
              <template v-slot:top>
                <q-radio v-model="Vigor"
                         :val="false"
                         label="Todas"
                         @update:model-value="updatePolizas"></q-radio>
                <q-radio v-model="Vigor"
                         :val="1"
                         label="En vigor"
                         @update:model-value="updatePolizas"></q-radio>
                <q-radio v-model="Vigor"
                         :val="0"
                         label="Anuladas o suplementos"
                         @update:model-value="updatePolizas"></q-radio>
              </template>
</q-table>
</div> -->
        </template>
        <template #tabB>
          <!-- <q-bar class="bg-secondary text-black rounded-borders">
            <q-space></q-space>
            <q-btn color="primary"
                   label="Volver"
                   @click="volver"></q-btn>
          </q-bar>
          <q-card class="my-card">
            <q-card-section>
              <q-list bordered
                      separator>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>CLIENTE </q-item-label>
                    <q-item-label>{{ cliente.Cliente }} [ {{ cliente.NIF }} ]</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>DIRECCION </q-item-label>
                    <q-item-label> {{ cliente.Direccion }} {{ cliente.Poblacion }} {{ cliente.CP }} {{ cliente.Provincia }} </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>CONTACTOS </q-item-label>
                    <q-item-label> <q-chip outline
                              square
                              icon="call"> {{ cliente.Movil }}</q-chip>
                      <q-chip outline
                              square
                              icon="tty"> {{ cliente.Fijo }}</q-chip>
                      <q-chip outline
                              square
                              icon="mail"> {{ cliente.Correo }}</q-chip>
                      <q-chip outline
                              square
                              icon="account_balance"> {{ cliente.Banco }}</q-chip> </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>NOTAS </q-item-label>
                    {{ cliente.Notas }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>
          <div class="q-pa-md">
            <q-table dense
                     title="Sinestros"
                     :rows="rows"
                     :columns="columnsSin"
                     row-key="name">
            </q-table>
          </div> -->
        </template>
      </DataTable>
    </div>
  </div>
</template>
<script setup>
import DataTable from "@/components/DataTable.vue"
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
const opts = ref({ table: '', url: null, post: null, quixk: '' })
const cliente = ref({})
const DT = ref()
const tab = ref();
// DATATABLE FUNCTIONS
const sel = ref(false)
const rows = ref([])
const Vigor = ref(1)
const subTable = ref("Recibos");
function onFilter(d) { }
function onUpdate(d) { }
function onSelRow(d) {
  if (d) {
    sel.value = true
    cliente.value = d
  } else sel.value = false
}
function updatePolizas() {
  let filtro = {}
  Vigor.value !== false ? filtro = { Vig: Vigor.value } : ""
  global.sql("/db/select/Polizas", { where: { ...filtro, NIF: cliente.value.NIF, Mediador: global.user.area } }, (r) => { rows.value = r.data })
}
function updateSiniestros() {
  global.sql("/db/select/Siniestros", { where: { Cliente: cliente.value.Cliente, Mediador: global.user.area } }, (r) => { rows.value = r.data })
}
function onTab() {
  if (tab.value !== "grid") {
    tab.value == "tabA" ? updatePolizas() : ""
    tab.value == "tabB" ? updateSiniestros() : ""
  }
}
function updateSubTable() {
  DT.value.clearDetailCache()
  opts.value.by2 = "Poliza"
  opts.value.byTable2 = subTable.value
}
// EMIT
function volver() {
  DT.value.tab = 'grid'
  tab.value = "grid"
  DT.value.update(opts.value.url)
  Vigor.value = 1
}
// APP CONST
// const buscar = ref();
// EXTRA FUNCTIONS
function init() {
  opts.value.table = "Clientes"
  opts.value.url = "/db/select/Clientes"
  opts.value.by = "NIF"
  opts.value.byTable = "Polizas"
  opts.value.by2 = "Poliza"
  opts.value.byTable2 = "Recibos"
  opts.value.defs = { Vig: 1 }
  opts.value.defs = { Mediador: global.user.area }
  opts.value.post = { where: { Mediador: global.user.area } } // no debe filtrar por mediador, lista única de clientes
  DT.value.update(opts.value.url, opts.value.post)
}
const columnsPol = [
  { name: 'Mediador', field: "Mediador", label: 'Mediador' },
  { name: 'Responsable', field: "Responsable", label: 'Responsable' },
  { name: 'Empresa', field: "Empresa", label: 'Empresa' },
  { name: 'Fecha', field: "Fecha", label: 'Fecha' },
  { name: 'Poliza', field: "Poliza", label: 'Poliza' },
  { name: 'SM', field: "SM", label: 'SM' },
  { name: 'Estado', field: "Estado", label: 'Estado' },
  { name: 'Riesgo', field: "Riesgo", label: 'Riesgo' },
  { name: 'Coberturas', field: "Coberturas", label: 'Coberturas' },
  { name: 'Prima', field: "Importe", label: 'Prima' },
  { name: 'Grupo', field: "Grupo", label: 'Grupo' },
]
const columnsSin = [
  { name: 'Mediador', field: "Mediador", label: 'Mediador' },
  { name: 'Responsable', field: "Responsable", label: 'Responsable' },
  { name: 'Empresa', field: "Empresa", label: 'Empresa' },
  { name: 'Fecha', field: "Fecha", label: 'Fecha' },
  { name: 'Poliza', field: "Poliza", label: 'Poliza' },
  { name: 'SM', field: "SM", label: 'SM' },
  { name: 'Estado', field: "Estado", label: 'Estado' },
  { name: 'Riesgo', field: "Riesgo", label: 'Riesgo' },
  { name: 'Coberturas', field: "Coberturas", label: 'Coberturas' },
  { name: 'Prima', field: "Importe", label: 'Prima' },
  { name: 'Grupo', field: "Grupo", label: 'Grupo' },
]
onMounted(() => { init() })
watch([route, global.user], () => { init() });
watch(() => tab.value, () => onTab())
</script>