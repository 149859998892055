<template>
  <div class="q-ma-xs">
    <q-toolbar v-if="route.params.id == 'Altas' || route.params.id == 'Bajas'"
               class="shadow-2 rounded-borders">
      <q-select outlined
                dense
                v-model="year"
                item-aligned
                style="width: 200px"
                :options="years()"
                label="Año"
                @update:model-value="init()">
      </q-select>
      <q-select outlined
                dense
                v-model="month"
                item-aligned
                style="width: 200px"
                :options="months()"
                label="Mes"
                @update:model-value="init()">
      </q-select>
      <q-space></q-space>
      <q-chip square
              color="primary"
              text-color="white"
              icon="euro">
        {{ importeNeto }}
      </q-chip>

    </q-toolbar>
    <DataTable ref="DT"
               :opts="opts"
               @onFilter="onFilter"
               @onUpdate="onUpdate"
               @onSelRow="onSelRow">
      <template #buttons>
        <q-btn-toggle v-model="subTable"
                      @update:model-value="updateSubTable()"
                      toggle-color="primary"
                      :options="[
                        { label: 'Recibos', value: 'Recibos' },
                        { label: 'Siniestros', value: 'Siniestros' }
                      ]" />
        <template v-if="sel === true">
          <q-btn v-if="selData.Responsable == 'Reale' && selData.Estado == 'NP'"
                 color="primary"
                 label="Asignar Póliza"
                 @click="assignPol(true)">
          </q-btn>
          <q-btn v-if="selData.Responsable != 'Reale' && selData.Estado == 'NP'"
                 color="primary"
                 label="DesAsignar Póliza"
                 @click="assignPol(false)">
          </q-btn>
          <q-btn v-if="selData.Estado !== 'AN' && selData.Estado !== 'NP'"
                 color="primary"
                 label="Baja Poliza"
                 @click="DBPonoff = true">
          </q-btn>
        </template>
      </template>
    </DataTable>
    <div class="row"
         v-if="opts.route !== 'Todos'">
      <q-chip dense
              square
              :style="{ backgroundColor: '#f5a9a9', color: 'black' }"
              icon="warning"> Póliza sin asignar, por defecto aparece
        REALE</q-chip>
      <q-chip v-if="route.params.id == 'Bajas'"
              dense
              square
              :style="{ backgroundColor: 'black', color: 'white' }"> Póliza ANULADA</q-chip>
    </div>
    <BajaPoliza :dialog="DBPonoff"
                :idpoliza="idpoliza"
                :fecha="fechaPol"
                :poliza="poliza"
                @dialog="DBPonoff = false"></BajaPoliza>
  </div>
</template>

<script setup>
// COMMON
import DataTable from "@/components/DataTable.vue"
import BajaPoliza from "@/components/BajaPoliza.vue"
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
// APP
const opts = ref({ table: '', url: null, post: null })
const DT = ref()
const years = () => Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
const year = ref(new Date().getFullYear())
const months = () => Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
const month = ref((new Date().getMonth() + 1).toString().padStart(2, '0'));
const importeNeto = ref()
const subTable = ref("Recibos")
//BAJAPOLIZA
const DBPonoff = ref(false)
const idpoliza = ref()
const poliza = ref()
const fechaPol = ref()
// DATATABLE FUNCTIONS
const sel = ref(false)
const selData = ref()
function onSelRow(d) {
  if (d) {
    sel.value = true
    selData.value = d
    idpoliza.value = d.id
    poliza.value = d.Poliza
    fechaPol.value = d.Fecha
  } else sel.value = false
}
function onFilter(d) { }
function onUpdate(d) {
  let dd = d.data
  importeNeto.value = global.data.getSum(dd, "ImporteNeto").toLocaleString('es-ES', { maximumFractionDigits: 2 });
}
function assignPol(val) {
  if (val === true) {
    global.sql("/db/update/Polizas", {
      data: { Responsable: global.user.name }, where: { id: selData.value.id }
    }).then(init())
  } else {
    global.sql("/db/update/Polizas", {
      data: { Responsable: 'Reale' }, where: { id: selData.value.id }
    }).then(init())
  }
}
function updateSubTable() {
  DT.value.clearDetailCache()
  opts.value.byTable = subTable.value
}
function init() {
  opts.value.table = "Polizas"
  opts.value.url = "/db/select/Polizas"
  opts.value.by = "Poliza"
  opts.value.byTable = "Recibos"
  opts.value.post = { where: { Mediador: global.user.area } }
  opts.value.defs = { Mediador: global.user.area, SM: '0', Fecha: global.data.getDate(0), Responsable: global.user.name, Wp: 0, Auto: 0, Estado: 'NP' }
  let fechaIni = new Date(Date.UTC(year.value, month.value - 1, 1)).toISOString().slice(0, 10);
  let fechaFin = new Date(Date.UTC(year.value, month.value, 0)).toISOString().slice(0, 10);
  if (route.params.id == "Altas") {
    opts.value.url = "/db/select/Polizas_Negocio"
    opts.value.defs['Estado'] = 'NP';
    opts.value.post = { where: { Estado: 'NP', Mediador: global.user.area }, between: { Fecha: { from: fechaIni, to: fechaFin } } }
  }
  if (route.params.id == "Bajas") {
    opts.value.url = "/db/select/Polizas_Negocio"
    opts.value.defs['Estado'] = 'AN';
    opts.value.post = { where: { Estado: 'AN', Mediador: global.user.area }, between: { FechaBaja: { from: fechaIni, to: fechaFin } } }
  }
  DT.value.update(opts.value.url, opts.value.post)
}
onMounted(() => { init() })
watch([route, () => global.user.area], () => { init() });
</script>