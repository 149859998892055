<template>
  <div class="q-mx-xs q-mt-xs">
    <DataTable ref="DT"
               :opts="opts"
               @onSelRow="onSelRow"
               @onFilter="onFilter"
               @onUpdate="onUpdate"></DataTable>
  </div>
</template>

<script setup>
import DataTable from "@/components/DataTable.vue"
import { inject, ref, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
const opts = ref({ table: '', url: null, post: null })
const DT = ref()
// DATATABLE FUNCTIONS
function onFilter(d) { }
function onUpdate(d) { }
function onSelRow(d) { }
// EXTRA FUNCTIONS
function init() {
  opts.value.table = route.params.id // Colaboradores, Ramos, Grupos, Mediadores, Siniestros, PolizasContactos[empresas], Usuarios, Parametros ...
  opts.value.url = "/db/select/" + opts.value.table;
  opts.value.defs = { Mediador: global.user.area }
  opts.value.post = { where: { Mediador: global.user.area } }
  let withoutMediador = ["Usuarios", "Parametros", "Mediadores", "PolizasContactos", "Empresas"]; // estos no filtran por mediador, ya que hay datos compartidos entre mediadores
  if (withoutMediador.includes(opts.value.table)) opts.value.post = {}
  opts.value.table === "Colaboradores" ? opts.value.post = { ...opts.value.post, order: ["SM", 'ASC'] } : "";
  opts.value.table === "Ramos" ? opts.value.post = { ...opts.value.post, order: ["Ramo", 'ASC'] } : "";
  opts.value.table === "PolizasContactos" ? opts.value.post = { ...opts.value.post, order: ["Empresa", 'ASC'] } : "";
  DT.value.update(opts.value.url, opts.value.post)
}
onMounted(() => { init() })
watch([route, () => global.user.area], () => { init() });
</script>