<template>
  <div>
    <q-tab-panels v-model="tab"
                  animated
                  transition-prev="scale"
                  transition-next="scale"
                  keep-alive
                  class="fit">
      <q-tab-panel style="padding: 2px"
                   name="grid">
        <!-- MAIN BAR-->
        <q-bar class="bg-secondary text-primary rounded-borders">
          <slot name="barL"></slot>
          <!-- BAR MENU DE ACCIONES DE REGISTROSº -->
          <q-btn v-if="!selRow"
                 dense
                 flat
                 color="primary"
                 icon="add"
                 @click="prepareData('insert')"></q-btn>
          <template v-if="selRow">
            <q-btn dense
                   flat
                   color="primary"
                   icon="edit"
                   @click="prepareData('update')"></q-btn>
            <q-btn dense
                   flat
                   color="primary"
                   icon="content_copy"
                   @click="prepareData('duplicate')"></q-btn>
            <q-btn dense
                   flat
                   color="primary"
                   icon="find_in_page"
                   @click="prepareData('view')"></q-btn>
            <q-btn dense
                   flat
                   color="negative"
                   icon="delete_forever"
                   @click="prepareData('delete')"></q-btn>
          </template>
          <q-space></q-space>
          <slot name="barC"></slot>
          <q-space></q-space>
          <slot name="barR"></slot>
          <!-- BAR BUSQUEDA RAPIDA -->
          <q-input borderless
                   v-model="quickFilter"
                   dense
                   color="primary"
                   placeholder="Buscar..."
                   style="width:250px">
            <template v-slot:prepend><q-icon :color="quickFilter !== '' ? 'negative' : 'grey'"
                      dense
                      name="search"></q-icon></template>
          </q-input>
          <!-- BAR SELECTOR DE FECHAS -->
          <q-btn dense
                 flat
                 color="primary"
                 icon="date_range"
                 v-if="fechaOptions.length > 0">
            <q-popup-proxy>
              <q-date v-model="modelDate"
                      minimal
                      range
                      @range-end="OkDate = true"
                      @range-start="OkDate = false"
                      mask="YYYY-MM-DD">
                <q-select v-model="fechaColumn"
                          :options="fechaOptions"
                          @update:model-value="onSelTypeDate"
                          label="Fecha a filtrar"
                          dense
                          options-dense
                          emit-value
                          map-options />
                <div class="row items-center justify-end">
                  <q-btn v-close-popup
                         label="Cerrar"
                         color="secondary"
                         flat></q-btn>
                  <q-btn v-if="OkDate"
                         v-close-popup
                         label="Borrar"
                         color="negative"
                         flat
                         @click="checkDate(false)"></q-btn>
                  <q-btn v-if="OkDate"
                         v-close-popup
                         label="OK"
                         @click="checkDate(true)"
                         color="primary"
                         flat></q-btn>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
          <!-- BAR BOTON DESCARGA EXCEL -->
          <q-btn color="primary"
                 flat
                 dense
                 icon="download"
                 @click="exportExcel"> </q-btn>
          <!--  BAR BOTON CHARTS -->
          <q-btn color="primary"
                 flat
                 dense
                 icon="bar_chart"
                 @click="viewChart"> </q-btn>
          <!-- BAR BOTON ACTUALIZAR DATOS -->
          <q-btn dense
                 flat
                 icon="refresh"
                 @click="update(props.opts.url, props.opts.post)"> </q-btn>
        </q-bar>
        <!-- SECOND BAR -->
        <q-bar class="bg-white text-primary rounded-borders">
          <div class="col q-gutter-xs">
            <slot name="buttons"></slot>
          </div>
          <q-space></q-space>
          <div>.</div>
        </q-bar>
        <!-- TABLE GRID --> <!-- editType="fullRow"-->
        <ag-grid-vue :animateRows="true"
                     :autoSizePadding="2"
                     :autoSizeStrategy="autoSizeStrategy"
                     :columnDefs="columnDefs"
                     :columnTypes="columnTypes"
                     :defaultColDef="defaultColDef"
                     :detailCellRendererParams="detailCellRendererParams"
                     :cellSelection="true"
                     :enterNavigatesVertically="true"
                     :enterNavigatesVerticallyAfterEdit="true"
                     :localeText="lang"
                     :masterDetail="true"
                     :rowData="rowData"
                     :rowSelection="rowSelection"
                     :rowrowDeselection="true"
                     :rowClassRules="rowClassRules"
                     :selectionColumnDef="selectionColumnDef"
                     :sideBar="sideBar"
                     :statusBar="statusBar"
                     :suppressAnimationFrame="false"
                     :suppressColumnVirtualisation="true"
                     :suppressDragLeaveHidesColumns="true"
                     :suppressMakeColumnVisibleAfterUnGroup="false"
                     :suppressMenuHide="true"
                     :undoRedoCellEditing="true"
                     undoRedoCellEditingLimit="20"
                     @filter-changed="onFilterChanged"
                     @model-updated="onModelUpdated"
                     @grid-ready="onGridReady"
                     @row-value-changed="onRowValueChanged"
                     @selection-changed="onSelectionChanged"
                     class="ag-theme-balham"
                     multiSortKey="ctrl"
                     rowGroupPanelShow="always"
                     style="height: 70vh">
        </ag-grid-vue>
      </q-tab-panel>
      <!-- PANEL DE EDICION DE DATOS -->
      <q-tab-panel style="padding: 2px"
                   name="edit">
        <!-- BOTONES DE GUARDAR Y VOLVER -->
        <q-bar class="bg-secondary text-primary">
          <q-btn color="negative"
                 label="cancelar"
                 icon="cancel"
                 @click="tab = 'grid'"></q-btn>
          <q-space></q-space>
          <!-- <q-btn color="primary" label="Subir Archivo" icon="upload" @click="tab = 'grid'"></q-btn> -->
          <q-space></q-space>
          <q-btn color="positive"
                 label="guardar"
                 icon="save"
                 @click="onSave()"> </q-btn>
        </q-bar>
        <q-scroll-area style="height: 70vh;"
                       class="shadow-2 rounded-borders">
          <!-- FORMULARIO EDITAR DATOS -->
          <q-form @submit="onSave"
                  ref="myForm">
            <div class="row q-col-gutter-xs q-pa-xs">
              <template v-for="(val, ind) in cols"
                        :key="ind">
                <!-- ES TEXTO ? -->
                <q-input outlined
                         class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                         v-if="(val.TYP == 'text') && !val.HID"
                         stack-label
                         :label="val.FLD"
                         v-model="editData[val.FLD]"
                         :rules="val.REQ ? [val => !!val || 'Campo requerido'] : []"
                         lazy-rules
                         dense
                         :hint="val.headerTooltip"
                         :disable="val.DIS"
                         autogrow></q-input>
                <!-- ES NUMERO ? -->
                <q-input outlined
                         class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                         v-if="(val.TYP == 'number' || val.TYP == 'money') && !val.HID"
                         type="number"
                         stack-label
                         :label="val.FLD"
                         v-model="editData[val.FLD]"
                         :rules="val.REQ ? [val => !!val || 'Campo requerido'] : []"
                         lazy-rules
                         dense
                         :hint="val.headerTooltip"
                         :disable="val.DIS"
                         autogrow></q-input>
                <!-- ES SELECT? -->
                <q-select outlined
                          class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                          v-if="val.TYP == 'select' && !val.HID"
                          stack-label
                          :label="val.FLD"
                          v-model="editData[val.FLD]"
                          :options="select[ind]"
                          use-input
                          dense
                          options-dense
                          :multiple="val.MUL"
                          :hide-selected="!val.MUL"
                          input-debounce="0"
                          :disable="val.DIS"
                          @filter="(va, update) => {
                            if (va === '') { update(() => select[ind] = val.LST) }
                            update(() => {
                              const list = val.LST;
                              select[ind] = list.filter(v => v !== null ? v.toString().toLowerCase().indexOf(va.toString().toLowerCase()) > -1 :
                                '')
                            })
                          }"
                          @input-value="(v) => { editData[val.FLD] = v; }"
                          fill-input
                          :rules="val.REQ ? [val => !!val || 'Campo requerido'] : []"
                          lazy-rules
                          :hint="val.headerTooltip"></q-select>
                <!-- ES FECHA? -->
                <q-input outlined
                         v-if="(val.TYP == 'datetime' || val.TYP == 'date') && !val.HID"
                         :label="val.FLD"
                         class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                         v-model="editData[val.FLD]"
                         :rules="val.REQ ? [val => !!val || 'Campo requerido'] : []"
                         :disable="val.DIS"
                         lazy-rules
                         dense
                         :hint="val.headerTooltip">
                  <template v-slot:append>
                    <q-icon name="event"
                            class="cursor-pointer">
                      <q-popup-proxy ref="qDateProxy"
                                     transition-show="scale"
                                     transition-hide="scale">
                        <q-date minimal
                                v-model="editData[val.FLD]"
                                mask="YYYY-MM-DD">
                          <div class="row items-center justify-end">
                            <q-btn v-close-popup
                                   label="Cerrar"
                                   color="primary"
                                   flat></q-btn>
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
                <!-- ES BIT? -->
                <q-checkbox v-if="val.TYP == 'bit' && !val.HID"
                            :label="val.FLD"
                            class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                            v-model="editData[val.FLD]"
                            :false-value="0"
                            :true-value="1"
                            :rules="val.REQ ? [val => !!val || 'Campo requerido'] : []"
                            :disable="val.DIS"
                            lazy-rules
                            dense
                            :hint="val.headerTooltip"></q-checkbox>
              </template>
            </div>
          </q-form>
        </q-scroll-area>
      </q-tab-panel>
      <!-- PANEL PARA VER DATOS MODO LECTURA DE DATOS-->
      <q-tab-panel style="padding: 2px"
                   name="view">
        <!-- BOTONES DE GUARDAR Y VOLVER -->
        <q-bar class="bg-secondary text-primary">
          <q-space></q-space>
          <q-btn color="primary"
                 label="Volver"
                 @click="tab = 'grid'"></q-btn>
        </q-bar>
        <div class="col q-col-gutter-xs q-pa-xs">
          <template v-for="(val, ind) in cols"
                    :key="ind">
            <q-input outlined
                     v-if="!val.HID"
                     stack-label
                     :label="val.FLD"
                     :model-value="Array.isArray(editData[val.FLD]) ? editData[val.FLD].join(', ') : editData[val.FLD]"
                     dense
                     readonly></q-input>
          </template>
        </div>
      </q-tab-panel>
      <!-- PANEL PARA GRAFICOS CHARTS -->
      <q-tab-panel style="padding: 2px"
                   name="chart">
        <q-bar class="bg-secondary text-primary rounded-borders">
          <q-btn color="primary"
                 label="Series">
            <q-menu>
              <q-list dense
                      style="min-width: 100px"
                      v-for="(value, index) in chart.seriesMenu"
                      :key="index">
                <q-item>
                  <q-item-section>
                    <div class="q-gutter-xs">
                      <q-radio v-for="(e, i) in chart.yAxis"
                               :key="i"
                               dense
                               v-model="chart.series[value.i].yAxis"
                               :val="i"
                               :color="e.color"
                               vertical>
                      </q-radio>
                      <q-icon name="colorize"
                              class="cursor-pointer"
                              :style="{ color: chart.series[value.i].color }">
                        <q-popup-proxy>
                          <q-color v-close-popup
                                   v-model="chart.series[value.i].color"
                                   no-header
                                   no-footer
                                   default-view="palette"
                                   class="my-picker"></q-color>
                        </q-popup-proxy>
                      </q-icon>
                      {{ value.name }}
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <q-checkbox dense
                      v-model="showBand"
                      @click="onShowBand"
                      label="Banda"></q-checkbox>
          <q-space></q-space>
          <q-btn color="primary"
                 label="Volver"
                 @click="tab = 'grid'"></q-btn>
        </q-bar>
        <highcharts :options="chart"
                    style="height: 70vh" />
      </q-tab-panel>
      <!-- MAS PANELES PARA CONFIGURACIONES PERSONALIZADAS TABA, TABB, TABC -->
      <q-tab-panel style="padding: 2px"
                   name="tabA">
        <slot name="tabA"></slot>
      </q-tab-panel>
      <q-tab-panel style="padding: 2px"
                   name="tabB">
        <slot name="tabB"></slot>
      </q-tab-panel>
      <q-tab-panel style="padding: 2px"
                   name="tabC">
        <slot name="tabC"></slot>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script setup>
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'; // Import the module
import { AgGridVue } from "@ag-grid-community/vue3";
import { ref, watch, inject, onMounted } from "vue";
import language from "../lang";
import { date, Dialog } from "quasar";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ExcelExportModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  RangeSelectionModule,
  RowGroupingModule,
  SetFilterModule,
  MultiFilterModule,
  SideBarModule,
  StatusBarModule,
  MasterDetailModule // Register the module
]);
// CONSTANTES GENERALES
const global = inject("global")
// CONST TAB
const tab = ref("grid")
// PROPS && EMITS
const props = defineProps({ opts: {} }); // propiedades de la tabla: table, table2, by, defs, post, url 
const emit = defineEmits(["onReady", "onSelRow", "onEdit", "onFilter", "onSave", "onUpdate"]); // emite hacia fuera
defineExpose({ tab, update, setFilter, clearDetailCache }); // es lo que se puede consultar en "ref" del componente y son funciones que se pueden llamar desde el componente padre
// CONST DATAEDIT
const myForm = ref(null);
const fullData = ref({})
const editData = ref({})
const where = ref({})
const select = ref([])
const url = ref()
const cols = ref()
// CONST DATEPICKER
const modelDate = ref()
const OkDate = ref()
const fechaColumn = ref()
const fechaOptions = ref([])
// CONST HIGHCHARTS
const defChart = {
  title: props.opts.table,
  chart: { type: "spline", zoomType: "xy" },
  lang: language.es.chart,
  credits: { enabled: false },
  series: [],
  xAxis: {},
  tooltip: { dateTimeLabelFormats: { day: '%d.%m.%y %Hh' } },
  plotOptions: {
    spline: { marker: { enabled: true } },
    column: { stacking: 'percent' },
    connectNulls: true,
    series: {
      events: {
        legendItemClick: function (event) {
          seriesActive({ name: this.name, col: this.color, i: this.index }, this.index)
          chart.value.series[this.index].visible = !chart.value.series[this.index].visible
        },
      },
    },
  },
  yAxis: [
    { opposite: false, events: { afterSetExtremes: function (e) { getAxis(e, 0) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#000000" } }, color: "black" },
    { opposite: true, events: { afterSetExtremes: function (e) { getAxis(e, 1) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#0023FF" } }, color: "blue" },
    { opposite: true, events: { afterSetExtremes: function (e) { getAxis(e, 2) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#66A94F" } }, color: "green" },
    { opposite: true, events: { afterSetExtremes: function (e) { getAxis(e, 3) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#9e9e9e" } }, color: "grey" },
    { opposite: true, events: { afterSetExtremes: function (e) { getAxis(e, 3) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#A52A2A" } }, color: "brown" },
    { opposite: true, events: { afterSetExtremes: function (e) { getAxis(e, 3) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#FF0000" } }, color: "red" },
    { opposite: true, events: { afterSetExtremes: function (e) { getAxis(e, 3) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#800080" } }, color: "purple" },
    { opposite: true, events: { afterSetExtremes: function (e) { getAxis(e, 3) } }, type: "linear", plotBands: [], title: { offset: 0, align: 'high', text: false, rotation: 0, y: -10 }, labels: { style: { color: "#008080" } }, color: "teal" },
  ],
  seriesMenu: [],
  accessibility: { enabled: false },
};
const chart = ref();
const chartSeries = ref([]);
const axis = ref([{ min: 0, max: 0 }, { min: 0, max: 0 }, { min: 0, max: 0 }, { min: 0, max: 0 }, { min: 0, max: 0 }, { min: 0, max: 0 }, { min: 0, max: 0 }, { min: 0, max: 0 }]);
const showBand = ref(false);
// CONST DATATABLE
const rowData = ref()
const columnDefs = ref()
const rowClassRules = ref({})
const lang = language.es.table;
const gridApi = ref();
const columnApi = ref();
const selRow = ref()
const selRowData = ref()
const quickFilter = ref('')
const columnTypes = {
  date: { filter: 'agSetColumnFilter', filterParams: { treeList: true, }, cellRenderer: (p) => { return p.value == "0000-00-00" || p.value == null ? "" : new Date(p.value).toLocaleDateString("es-ES") } },
  datetime: { filter: 'agSetColumnFilter', filterParams: { treeList: true, } },
  number: { enableValue: true, filterParams: { filters: [{ filter: "agNumberColumnFilter" }, { filter: "agSetColumnFilter" }] } },
  money: { valueFormatter: (p) => p.value !== undefined && p.value !== null ? p.value.toLocaleString('es', { useGrouping: true, minimumFractionDigits: 2 }) + " €" : '', enableValue: true, filterParams: { filters: [{ filter: "agNumberColumnFilter" }, { filter: "agSetColumnFilter" }] } },
  text: { valueFormatter: (p) => p.value !== null && p.value !== undefined ? p.value.toString() : '', filterParams: { filters: [{ filter: "agTextColumnFilter" }, { filter: "agSetColumnFilter" }] } },
  bit: { cellRenderer: function (params) { return `<input type='checkbox' onclick="return false;" ${params.value == 0 ? "" : "checked"} />`; } },
  select: {}
};
const defaultColDef = {
  enablePivot: true,
  editable: false,
  resizable: true,
  filter: "agMultiColumnFilter",
  sortable: true,
  enableRowGroup: true,
  floatingFilter: false,
  menuTabs: ['filterMenuTab', 'generalMenuTab']
};
const statusBar = {
  statusPanels: [
    { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
    { statusPanel: "agFilteredRowCountComponent" },
    { statusPanel: "agSelectedRowCountComponent" },
    { statusPanel: "agAggregationComponent" }],
};
const sideBar = {
  toolPanels: [
    { id: "columns", labelDefault: "Columns", labelKey: "columns", iconKey: "columns", toolPanel: "agColumnsToolPanel" },
    { id: "filters", labelDefault: "Filters", labelKey: "filters", iconKey: "filter", toolPanel: "agFiltersToolPanel" }],
  defaultToolPanel: "",
};
const rowSelection = {
  mode: 'singleRow'
}
const autoSizeStrategy = {
  type: 'fitCellContents'
};
const selectionColumnDef = {
  pinned: 'left',
  headerTooltip: 'Checkboxes indicate selection',
};

const detailCache = {}; // Cache global
const detailCache2 = {}; // Cache global

const detailCellRendererParams = ref({
  detailGridOptions: {
    columnDefs: [],
    columnTypes,
    masterDetail: true, // Solo activar si by2 está definido
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [],
        columnTypes,
      },
      defaultColDef: {
        ...defaultColDef,
        sortable: true,
        filter: true,
      },
      getDetailRowData: async (params) => {
        // Implementación para el sub-detalle
        const key2 = params.data[props.opts.by2];
        if (detailCache2[key2])
          return params.successCallback(detailCache2[key2]);
        const r = await global.sql(`/db/select/${props.opts.byTable2}`, { where: { [props.opts.by2]: key2 } }, false, false);
        const newColumnDefs = r.cols.map(({ FLD, HDN, TYP }) => ({ field: FLD, hide: HDN, type: TYP }));
        if (JSON.stringify(detailCellRendererParams.value.detailGridOptions.detailCellRendererParams.detailGridOptions.columnDefs) !== JSON.stringify(newColumnDefs))
          detailCellRendererParams.value.detailGridOptions.detailCellRendererParams.detailGridOptions.columnDefs = newColumnDefs;
        detailCache2[key2] = r.data;
        params.successCallback(r.data);
      }
    }
  },
  defaultColDef: {
    ...defaultColDef,
    sortable: true,
    filter: true,
  },
  getDetailRowData: async (params) => {
    const key = params.data[props.opts.by];
    if (detailCache[key])
      return params.successCallback(detailCache[key]);
    const r = await global.sql(`/db/select/${props.opts.byTable}`, { where: { [props.opts.by]: key } }, false, false);
    const newColumnDefs = r.cols.map(({ FLD, HDN, TYP }) => ({ field: FLD, hide: HDN, type: TYP })); // Actualizar columnas solo si cambian
    if (JSON.stringify(detailCellRendererParams.value.detailGridOptions.columnDefs) !== JSON.stringify(newColumnDefs))
      detailCellRendererParams.value.detailGridOptions.columnDefs = newColumnDefs;
    detailCache[key] = r.data;
    if (props.opts.hasOwnProperty('by2')) {// Encuentra la primera columna no oculta
      const firstVisibleColumn = detailCellRendererParams.value.detailGridOptions.columnDefs.find(col => !col.hide);
      if (firstVisibleColumn)
        firstVisibleColumn.cellRenderer = 'agGroupCellRenderer';
    }
    params.successCallback(r.data);
  }
});

// FUNCTIONS DATEPICKER
function checkDate(state) {
  const Fecha = fechaColumn.value;
  state === true && OkDate.value === true ? update(props.opts.url, { ...props.opts.post, between: { [Fecha]: modelDate.value } }) : OkDate.value = modelDate.value = false
}
function onSelTypeDate(d) {
  updateDateField(columnDefs.value)
}
// FUNCTION CHARTS
function viewChart() {
  const sortedData = chartSeries.value.sort((a, b) => a[fechaColumn.value] - b[fechaColumn.value]);
  const series = columnDefs.value
    .filter((columnDef) => !columnDef.field.startsWith('id') && ['number', 'money'].includes(columnDef.type))
    .map((columnDef) => ({
      name: columnDef.field,
      data: sortedData
        .map((row) => ({ x: new Date(row[fechaColumn.value]).getTime(), y: row[columnDef.field] }))
        .sort((a, b) => a.x === b.x ? a.y - b.y : a.x - b.x),
      visible: false // Agrega esta propiedad para desactivar la serie inicialmente
    }));
  chart.value = { ...defChart, series, xAxis: [{ title: { text: fechaColumn.value }, type: 'datetime', dateTimeLabelFormats: { day: '%d/%m/%Y', week: '%d/%m', month: '%m/%Y', year: '%Y' } }] };
  tab.value = "chart"
}
function getAxis(value, ax) {
  axis.value[ax].min = value.dataMin
  axis.value[ax].max = value.dataMax
}
function seriesActive(value, id) {
  let exist = false
  for (var i = 0; i < chart.value.seriesMenu.length; i++) {
    if (chart.value.seriesMenu[i].name === value.name) {
      chart.value.seriesMenu.splice(i, 1);
      exist = true
      break;
    }
  }
  if (!exist) {
    chart.value.seriesMenu.push(value)
    chart.value.series[id].color = value.col
  }
}
function onShowBand() {
  chart.value.yAxis[0].plotBands = showBand.value ? chart.value.plotBands : [];
}
// FUNCTION TABLE
function onFilterChanged(event) { //cuando se filtra y actualiza
  let rowData = [];
  event.api.forEachNodeAfterFilter((node) => { rowData.push(node.data) });
  emit("onFilter", rowData); // emitimos onFilter con los datos actualizados
  chartSeries.value = rowData
}
function onRowValueChanged(event) { // cuando se edita una linea (deberia eliminarse ya que hay que evitar modificar lineas en el grid, solo en formulario)
  prepareData("update")
  onSave()
  emit("onEdit", event.data)
}
function onModelUpdated(event) { // cuando se ha cargado el modelo completo. Asi evitamos que se queden seleccionados cuando actualizas la pagina
  onSelectionChanged(event)
}
function onSelectionChanged(event) { // cuando marcamos una seleccion 
  selRow.value = event.api.getSelectedNodes().length == 1 ? true : false;
  selRowData.value = event.api.getSelectedNodes().length == 1 ? event.api.getSelectedNodes()[0].data : {};
  selRow.value ? emit("onSelRow", selRowData.value) : emit("onSelRow", false)
  selRow.value ? fullData.value = selRowData.value : ""
}
function onGridReady(params) { // cuando se actualiza el grid
  gridApi.value = params.api;
  columnApi.value = params.columnApi;
  params.api.sizeColumnsToFit();
}
function setFilter(d) { // cuando realizamos un filtro
  gridApi.value.setFilterModel(d);
}
function exportExcel() { //exportacion a excel
  let excelParams = { fileName: "ExpotarDare.xlsx", sheetName: "Dare", };
  gridApi.value.exportDataAsExcel(excelParams);
}
// FUNCION DATAEDIT MODO FORMULARIO
function prepareData(action) {
  editData.value = global.data.clone(fullData.value)
  where.value = {} // limpio where al preparar datos
  if (cols.value !== undefined) {
    // INSERT
    if (action === "insert") {
      cols.value.forEach((el) => {
        editData.value[el.FLD] = el.DEF
        if (el.TYP == "datetime") editData.value[el.FLD] = date.formatDate(Date.now(), "YYYY-MM-DD HH:mm");
        if (el.TYP == "date") editData.value[el.FLD] = date.formatDate(Date.now(), "YYYY-MM-DD");
        if (el.TYP == "text" || el.TYP == "select") editData.value[el.FLD] = el.DEF ?? null;
        if (el.TYP == "number" || el.TYP == "bit" || el.TYP == "money") editData.value[el.FLD] = el.DEF ?? 0;
        if (el.TYP == "select" && el.MUL) editData.value[el.FLD] = []; // para select multiple
        if (el.FLD == "Usuario") editData.value[el.FLD] = global.user.name; // esto mejor definirlo en opts.defs para cada consulta y eliminarlo de aqui
        if (props.opts.hasOwnProperty("defs")) editData.value[el.FLD] = props.opts.defs[el.FLD] // permite rellenar campos automaticamente desde props.opts de parent defs={Mediador=22}
      });
      if (editData.value.hasOwnProperty("id")) delete editData.value["id"]; // elimina la id porque se genera automaticamente en la BD
      tab.value = "edit"
      url.value = "/db/insert/" + props.opts.table
    }
    // DUPLICATE (INSERT)
    if (action === "duplicate") {
      // aqui indicamos que campo es el que se actualizara para casos de fechas, "upd='FechaRegistro'" desde el parent, en caso contrario sera "Fecha"
      // por defecto pone la fecha que le hemos seleccionado aqui, es decir si generamos un props.opts.upd['FechaVencimiento'] esta sera la fecha seleccionada para poner la fecha de ahora al duplicar registro
      if (props.opts.hasOwnProperty("upd")) {
        editData.value[props.opts.upd] = date.formatDate(Date.now(), "YYYY-MM-DD HH:mm");
      }
      else {
        if (editData.value.hasOwnProperty("Fecha")) editData.value["Fecha"] = date.formatDate(Date.now(), "YYYY-MM-DD HH:mm"); // en caso contrario, será "Fecha" la fecha a poner el valore de ahora al duplicar
      }
      if (editData.value.hasOwnProperty("id")) delete editData.value["id"]; // eliminamos el id de insert(duplicate) o da error, intentariamos escribir un nuevo registro con una id existente
      tab.value = "edit"
      url.value = "/db/insert/" + props.opts.table
    }
    // UPDATE / VIEW
    if (action === "update" || action === "view") {
      if (action === "update") delete editData.value["id"];
      tab.value = action === "update" ? "edit" : "view";
      url.value = action === "update" ? "/db/update/" + props.opts.table : "";
      where.value = action === "update" ? { where: { id: fullData.value.id } } : {}; //siempre enviamos id
      cols.value.forEach((el) => {
        if (el.TYP == "select" && el.MUL && editData.value[el.FLD] !== null && editData.value[el.FLD] !== undefined) {
          el.LST = el.LST.map(num => num.toString())
          editData.value[el.FLD] = editData.value[el.FLD].toString().split(',')
        }
      });
    }
    // DELETE
    if (action === "delete") {
      Dialog.create({
        title: "Confirma",
        message: "¿Eliminar el registro?",
        cancel: true,
        persistent: true,
        ok: { color: "negative" }
      }).onOk(() => {
        global.sql("/db/delete/" + props.opts.table, { where: { id: fullData.value.id } }).then(update(props.opts.url, props.opts.post))
      })
      tab.value = 'grid'
    }
  }
  emit("onEdit", { insert: editData.value })
}
// Al guardar el registro
function onSave() {
  if (myForm.value !== null)
    myForm.value.validate().then(success => {
      if (success) {
        if (cols.value !== undefined) {
          cols.value.forEach((el) => {
            if (editData.value[el.FLD] !== undefined && editData.value[el.FLD] !== null) {
              if (el.TYP == "number" || el.TYP == "bit" || el.TYP == "money") {
                editData.value[el.FLD] = parseFloat(editData.value[el.FLD].toString().replace(",", ".")) // Convierto "," en "." por estandarizacion
              } else if (el.TYP == "select" && el.MUL) {
                editData.value[el.FLD] = Array.isArray(editData.value[el.FLD]) ? editData.value[el.FLD].join(',').trim().replace(/^,+|,+$/g, '') : editData.value[el.FLD];
              }
            }
          })
        }
        global.sql(url.value, { data: editData.value, ...where.value }, () => { }).then(setTimeout(update, 1000, props.opts.url, props.opts.post));
        tab.value = 'grid';
      } else {
        return;
      }
    });
}
// funcion para limpiar la cache de la subtabla
function clearDetailCache() {
  if (gridApi.value && !gridApi.value.isDestroyed()) {
    if (detailCache) {
      Object.keys(detailCache).forEach(key => delete detailCache[key]);
    }
    if (detailCache2) {
      Object.keys(detailCache2).forEach(key => delete detailCache2[key]);
    }
    // Actualizar la cuadrícula si es necesario
    gridApi.value.refreshCells({ force: true });
  } else {
    console.warn('La cuadrícula ha sido destruida o no está inicializada. No se puede limpiar el caché.');
  }
}
// UPDATE DATA FROM SERVER
async function update(url, data = {}) { // capturamos los datos de data, cols y rules
  await global.sql(url, data, (res) => {
    if (res.success) {
      cols.value = res.cols;
      columnDefs.value = cols.value.map(col => ({ field: col.FLD, hide: col.HDN, type: col.TYP, pinned: col.PIN, cellClassRules: col.RUL, rowGroup: col.GRP })); // extraemos cols a columnDefs
      rowData.value = res.data;
      rowClassRules.value = res.rules;
      if (props.opts.hasOwnProperty('by')) {
        // Encuentra la primera columna no oculta
        const firstVisibleColumn = columnDefs.value.find(col => !col.hide);
        if (firstVisibleColumn)
          firstVisibleColumn.cellRenderer = 'agGroupCellRenderer';
      }
      updateDateField(columnDefs.value) // crea la lista de posibles "Fechas" que podemos seleccionar en el calendario a la hora de filtrar por fecha (FechaEfecto, Fecha, FechaVigencia...)
      chartSeries.value = res.data // actualiza las series del grafico
      emit("onUpdate", { data: res.data, cols: res.cols }) //emitimos onUpdate con los datos actualizados
    }
  })
}
function updateDateField(val) { // Crea un array de campos que se llamen "Fecha" para mostrar en combo en el calendario de buscar por fecha (de esa seleccion)
  const opciones = val.filter((col) => col.field.includes('Fecha')).map((col) => ({ label: col.field, value: col.field })).sort((a, b) => a.label.localeCompare(b.label))
  fechaOptions.value = opciones
  fechaColumn.value = opciones.length > 0 ? opciones[0].value : ''
}
onMounted(() => { })
// WATCHS
watch(() => quickFilter.value, () => { gridApi.value.setGridOption('quickFilterText', quickFilter.value); })
</script>

<style>
.ag-theme-balham .RO {
  background-color: #f5a9a9;
}

.ag-theme-balham .VE {
  background-color: #b6ffbf;
}

.ag-theme-balham .AZ {
  background-color: #88c9ff;
}

.ag-theme-balham .AM {
  background-color: #fcf18e;
}

.ag-theme-balham .GR {
  background-color: #a8a8a7;
}

.ag-theme-balham .NG {
  background-color: #000000;
  color: #ffffff
}
</style>
