<template>
  <div class="iframe-container">
    <!-- <iframe id="myIframe":src="url" allowfullscreen @load="onIframeLoad"></iframe> -->
    <iframe id="myIframe":src="url" @load="onIframeLoad"></iframe>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router"
import { Loading } from "quasar";
const route = useRoute();
const url = ref();
// FUNCIONES
function onIframeLoad() {
  setTimeout(function() {Loading.hide()},3000)
}
function init() {
  Loading.show()
  url.value = "https://totsegur.es/" + route.params.id
}
onMounted(() => { init() })
watch(route, () => { init() });
</script>
<style>
/* Estilo para el contenedor del iframe */
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 94vh; /* Usa toda la altura de la ventana */
  /* Proporción 16:9 */
}

/* Estilo para el iframe */
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  max-width: 100%; /* Asegura que no exceda el ancho del contenedor */
}
</style>