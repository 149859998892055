<template>
  <div class="q-pa-md q-gutter-sm">
    <h5>Selecciona plantilla a editar </h5>
    <div class="row">
      <div class="col-9">
        <q-select dense
                  outlined
                  v-model="plant"
                  :options="plants"
                  label="Selecciona Plantilla"
                  @update:model-value="onSelect"></q-select>
      </div>
      <div class="col-3">
        <q-select dense
                  outlined
                  v-model="table"
                  :options="tables"
                  label="Selecciona Tabla"
                  @update:model-value="onTable"></q-select>
      </div>
    </div>
    <div>
      <q-chip square
              outline
              color="primary"
              text-color="white"
              icon="info">
        {{ info }}
      </q-chip>
    </div>
    <div class="col-12">
      <q-input readonly
               outline
               autogrow
               label="Etiquetas disponibles"
               v-model="availableFields"></q-input>
    </div>
    <div class="col">
      <q-input dense
               outlined
               v-model="to"
               label="Para" />
      <q-input dense
               outlined
               v-model="cc"
               label="Con Copia" />
      <q-input dense
               outlined
               disabled
               v-model="subject"
               label="Asunto" />
    </div>
    <q-editor :toolbar="[
      ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
      ['hr', 'link'],
      ['left', 'center', 'right', 'justify'],
      ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
      ['token',
        {
          label: $q.lang.editor.formatting,
          icon: $q.iconSet.editor.formatting,
          list: 'no-icons',
          options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
        },
        {
          label: $q.lang.editor.fontSize,
          icon: $q.iconSet.editor.fontSize,
          fixedLabel: true,
          fixedIcon: true,
          list: 'no-icons',
          options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
        },
        {
          label: $q.lang.editor.defaultFont,
          icon: $q.iconSet.editor.font,
          fixedIcon: true,
          list: 'no-icons',
          options: ['default_font', 'arial', 'arial_black', 'comic_sans', 'courier_new', 'impact', 'lucida_grande', 'times_new_roman', 'verdana']
        },
        'removeFormat'
      ],
      ['print', 'fullscreen'],
      ['undo', 'redo'],
      ['viewsource']
    ]"
              :fonts="{
                arial: 'Arial',
                arial_black: 'Arial Black',
                comic_sans: 'Comic Sans MS',
                courier_new: 'Courier New',
                impact: 'Impact',
                lucida_grande: 'Lucida Grande',
                times_new_roman: 'Times New Roman',
                verdana: 'Verdana'
              }"
              ref="targetRef"
              v-model="editor">
      <template v-slot:token>
        <q-btn-dropdown dense
                        no-caps
                        ref="token"
                        no-wrap
                        unelevated
                        color="white"
                        text-color="primary"
                        label="Color"
                        size="sm">
          <q-list dense>
            <q-item tag="label"
                    clickable
                    @click="color('backColor', highlight)">
              <q-item-section side>
                <q-icon name="highlight"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-color v-model="highlight"
                         default-view="palette"
                         no-header
                         no-footer
                         :palette="[
                          '#ffccccaa', '#ffe6ccaa', '#ffffccaa', '#ccffccaa',
                          '#ccffe6aa', '#ccffffaa', '#cce6ffaa', '#ccccffaa', '#e6ccffaa', '#ffccffaa', '#ff0000aa', '#ff8000aa', '#ffff00aa', '##00ff00aa', '#1f9547aa', '#000000aa', '#0080ffaa', '#0000ffaa', '#8000ffaa', '#ff00ffaa'
                        ]"
                         class="my-picker"></q-color>
              </q-item-section>
            </q-item>
            <q-item tag="label"
                    clickable
                    @click="color('foreColor', foreColor)">
              <q-item-section side>
                <q-icon name="format_paint"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-color v-model="foreColor"
                         no-header
                         no-footer
                         default-view="palette"
                         :palette="[
                          '#ff0000', '#ff8000', '#ffff00', '##00ff00', '#1f9547', '#000000', '#0080ff', '#0000ff', '#8000ff', '#ff00ff'
                        ]"
                         class="my-picker"></q-color>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </template>
    </q-editor>
    <div class="row">
      <q-btn color="primary"
             label="GUARDAR"
             @click="onSave"></q-btn>
      <q-btn dense
             label="Enviar correo de prueba"
             @click="test()"></q-btn>
    </div>
    <div class="col">
      <u><i>Otras Etiquetas disponibles:</i></u><br>
      <b>:Recibos_Pendientes_hoy</b> (Muestra una lista de recibos pendientes hasta hoy)<br>
      <b>:Recibos_Pendientes_Colaborador</b> (Muestra una lista de recibos en estado PENDIENTE)<br>
      <b>:Usuario</b> (el usuario de la sesión actual)<br>
      <b>:Correo</b> (el correo del usuario actual)<br>
      <b>:Fecha</b> (La fecha de hoy)<br>
    </div>

  </div>
</template>

<script setup>
import { useQuasar } from "quasar";
import { ref, getCurrentInstance, inject, onMounted } from "vue"
const global = inject("global")
const $q = useQuasar()

// CONST
const info = ref()
const editor = ref('')
const plant = ref()
const plants = ref([])
const id = ref()
// test
const to = ref('natxocc@gmail.com')
const cc = ref('test@gmail.com')
const subject = ref('Missatge de prova')
//COLOR
const foreColor = ref('#000000')
const highlight = ref('#ffff00aa')
const targetRef = ref(null)
const token = ref(null)
const { proxy } = getCurrentInstance()

function color(cmd, name) {
  proxy.$refs.token.hide()
  proxy.$refs.targetRef.runCmd(cmd, name)
  proxy.$refs.targetRef.focus()
}
// TABLE

const table = ref()
const tables = ["Liquidaciones", "Clientes", "Polizas", "Recibos", "Siniestros"]
const availableFields = ref()
//FUNCTS

function onSelect(val) {
  global.sql("/db/select/Plantillas", { where: { Nombre: val } }, (r) => {
    editor.value = r.data[0].Mensaje
    info.value = r.data[0].Info
    to.value = r.data[0].Para
    cc.value = r.data[0].ConCopia
    subject.value = r.data[0].Asunto
    table.value = r.data[0].Tabla
    id.value = r.data[0].id
  }).then(() => onTable())
}
function onTable() {
  global.sql("/template/getTags", { table: table.value }, (r) => {
    // Add a colon before each field name
    if (r.data && Array.isArray(r.data)) {
      availableFields.value = r.data.map(field => `:${field}`).join(', ');
    } else {
      availableFields.value = r.data;
    }
  })
}
function onSave() {
  global.sql("/db/update/Plantillas", {
    data: {
      Mensaje: editor.value,
      Tabla: table.value,
      Asunto: subject.value,
      Para: to.value,
      CC: cc.value
    }, where: { Nombre: plant.value }
  })
}

function test() {
  global.sql("/app/testMail", { id: id.value })
}
function init() {
  global.sql("/db/select/Plantillas", {}, (r) => {
    r.success ? plants.value = global.data.getColumn(r.data, "Nombre") : "";
  })
}
onMounted(() => { init() })
</script>
