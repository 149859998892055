<template>
  <div class="q-mx-xs q-mt-xs">
    <q-scroll-area style="height: 600px;" class="shadow-2 rounded-borders">
      <q-timeline color="secondary">
        <q-timeline-entry v-for="step in historico" :title="`${step.Usuario} (Paso: ${step.Paso})`" :subtitle="step.FechaRegistro">
          <div> Acción: <b>{{ step.Descripcion }}</b> </div>
          <div> Respuesta: <b>{{ step.Respuesta }}</b> </div>
          <div v-if="step.FechaAgenda !== null">
            Agendado al: <b>{{ step.FechaAgenda }}</b>
          </div>
          <div>
            Comentarios: <span v-html="step.Comentarios"></span>
          </div>
        </q-timeline-entry>
      </q-timeline>
    </q-scroll-area>
  </div>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';
const global = inject("global")
const props = defineProps({ opts: {}, tab: '' })
// RECIBOS HISTORICO
const historico = ref()
function init() {
  historico.value = null
  global.sql("/db/select/RecibosGestion", { where: { Recibo: props.opts.Recibo }, order: ["FechaRegistro", "ASC"] },
    (res) => {
      if (res.success === true) {
        historico.value = res.data.map((step) => {
          step.Comentarios = step.Comentarios.replace(/<[^>]*>/g, '');
          return step;
        });
      }
    })
}
onMounted(() => { init() })
watch(() => props.tab, (n, o) => { if (props.tab == "tabB" && n !== o) init() })
</script>